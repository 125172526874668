<template>
    <div class="row">
        <div class="col-6 col-md-4">
            <fg-input class="no-border d-inline-block w-100 mr-3"
                      type="number"
                      label="Bid Year"
                      :maxlength="4"
                      :disabled="!isSuper"
                      v-if="!hideInput"
                      v-model="new_year" />
        </div>
        <div class="col pt-3">
            <n-button round
                      type="success"
                      :disabled="!canChange"
                      @click.native="newYear()">
                <i class="fas fa-calendar mr-2"></i> Start New Bid Year ({{ this.year }})
            </n-button>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import moment from 'moment'
const FacilityRepository = RepositoryFactory.get('facilities')

export default {
  name: 'ManageBidYear',
  components: {},
  props: {
    facility: Object,
    isSuper: Boolean,
    hideInput: Boolean
  },
  data () {
    return {
      new_year: 0,
      dirty: false
    }
  },
  methods: {
    newYear () {
      this.$confirm('This change will take a few minutes to complete. You can continue working but ' +
        'will need to reload the page in a few minutes to have full access to the system. ' +
        'Last year\'s data will be archived until the end of this bid year.', 'Change Bid Year', {
        type: 'info',
        confirmButtonText: 'Change Bid Year'
      }).then(() => {
        FacilityRepository.update(this.facility.id, { bid_year: this.year })
          .then(r => {
            this.$emit('facility', r.data)
            this.$emit('year', this.year)
            this.new_year = r.data.bid_year
            this.$store.dispatch('facility/set', this.facility.id)
          })
      })
    }
  },
  computed: {
    canChange () {
      return this.isSuper || this.facility.bid_year === parseInt(moment().format('Y'))
    },
    year () {
      return this.dirty ? this.new_year : this.new_year + 1
    }
  },
  created () {},
  mounted () {
    this.new_year = this.facility.bid_year
  },
  watch: {
    new_year (n) {
      // If user changes (super) then only send what they have entered in the box
      if (parseInt(n) !== this.facility.bid_year) { this.dirty = true }
    }
  }
}
</script>

<style scoped>

</style>
